
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init(1500)
        })
        barba.hooks.after((data) => {
            this.init(1200)
        });
    }
    init (delay){
       const heroCatch = document.querySelector(".p-index__hero")
        if(heroCatch){
          
            setTimeout(function(){
                let timeline =  anime.timeline({
                    delay:delay
                });
                timeline.add({
                    targets : '#p-index__hero--image-cover',
                    translateX:[0,"100%"],
                    easing: "easeOutExpo",
                    duration:1000
                })
                .add({
                    targets : '#p-index__hero--catch-svg path',
                    strokeDashoffset:[anime.setDashoffset,0],
                    easing: "easeOutSine",
                    duration:1800
                },"-=1800")
                .add({
                    targets : '#p-index__hero--lead-first',
                    translateY: ["20px",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:800
                },"-=1500")
            })
       }
    }
}